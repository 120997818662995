@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap');

.HH-Bg-Image {
    background: url('../Assets/Home_Bg_Image.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    height: 500px;
}

.HH-Main_Div {
    display: flex;
}

.HH-Main_Div {
    display: flex;
    width: 100%;
}

.HH-Left_Img {
    display: flex;
    justify-content: flex-start;
}

.HH-Right_Img {
    display: flex;
    justify-content: flex-end;
}

.HH-Center_Content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.HH-Left_Img,
.HH-Right_Img {
    width: 450px;
    height: 500px;
}

.HH-Right_Img {
    float: right;
}

.HH-Left_Img>img,
.HH-Right_Img>img {
    width: 100%;
    height: 100%;
}

.HH-Main_Div {
    padding-top: 40px;
}

.HH-CC-HeadingOne {
    color: #fff;
}

.HH-CC-HeadingTwo {
    color: var(--Clr-Theam);
}

.HH-Center_Content>.Content>h1 {
    font-family: var(--Head-Font) !important;
    text-align: center;
    font-weight: 600;
}

.HH-CC-Description {
    text-align: center;
    color: white;
    font-size: 20px;
}

.HH-Main_Div > div {
    width: 33.33%;
}

.navbar {
    --bs-navbar-padding-y: 0rem !important;
}

.navbar-brand {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.HH-CC-Play_Now:hover {
    background-color: #2dcb2a;
}

.HH-CC-Play_Now {
    text-decoration: none;
    color: black;
    background: var(--Clr-Theam);
    padding: 10px 0px;
    border-radius: 10px;
    font-size: 20px;
    font-family: var(--Head-Font);
    font-weight: 500;
    text-align: center;
    display: block;
    width: 175px;
}

.HH-CC-Link_Div {
    display: flex;
    justify-content: center;
}

.GB-HomePage {
    margin-top: 20px !important;
}

.GB-HomePage > h2 {
    color: white;
    font-size: 29px;
    font-family: "Oxanium" sans-serif;
    font-weight: 600;
    border-left: 5px solid var(--Clr-Theam);
    padding-left: 15px;
    margin-left: 16px;
    border-radius: 0px;
    display: inline;
    padding-right: 15px;
}

.GB-HomePage > h2:hover {
    background-color: var(--Clr-Theam);
    color: black;
    border-radius: 10px;
}

.GB-HomePage > h2:hover span {
    color: black;
}

.GB-HomePage > h2 > span {
    color: var(--Clr-Theam);
}