@media (max-width: 1150px) {
    .HH-Right_Img_Div {
        display: none;
    }

    .HH-Main_Div>div {
        width: 50%;
        font-size: 25px;
    }
}

@media (max-width: 970px) {
    .HH-Main_Div>div {
        font-size: 20px;
    }
}

@media (max-width: 777px) {
    .HH-Left_Img_Div {
        display: none;
    }

    .HH-Main_Div>div {
        width: 100%;
        height: 400px;
    }

    .HH-CC-Description {
        width: 50%;
        margin: 15px auto;
    }

    .Content>h1 {
        font-size: 40px;
    }
}

@media (max-width: 650px) {
    .HH-CC-Description {
        width: 80%;
    }
}

/* Cursor Style */
/* html {
    scrollbar-color: #AAADBE transparent;
    scrollbar-width: thin;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    color: white;
    background-color: #000;
    -webkit-text-size-adjust: 100%;
} */

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

:root {
    --Game-Bg-Color: #7c79b3;
    --Nikal: none;
}

::-webkit-scrollbar {
    height: 4px;
}

.Image_Box>a>img {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: -4px !important;
}

/* Game Box Responsive Css */
@media (min-width: 750px) {
    .Image_Box {
        width: 215px !important;
        height: 215px !important;
    }
}

@media (max-width: 750px) {
    .Games_Box>.Image_Box {
        width: 210px !important;
        height: 210px !important;
    }
}

@media (max-width: 736px) {
    .Games_Box>.Image_Box {
        width: 205px !important;
        height: 205px !important;
    }
}

@media (max-width: 720px) {
    .Games_Box>.Image_Box {
        width: 200px !important;
        height: 200px !important;
    }
}

@media (max-width: 703px) {
    .Games_Box>.Image_Box {
        width: 195px !important;
        height: 195px !important;
    }
}

@media (max-width: 685px) {
    .Games_Box>.Image_Box {
        width: 190px !important;
        height: 190px !important;
    }
}

@media (max-width: 669px) {
    .Games_Box>.Image_Box {
        width: 185px !important;
        height: 185px !important;
    }
}

@media (max-width: 652px) {
    .Games_Box>.Image_Box {
        width: 180px !important;
        height: 180px !important;
    }
}

@media (max-width: 634px) {
    .Games_Box>.Image_Box {
        width: 280px !important;
        height: 280px !important;
    }
}

@media (max-width: 655px) {
    .Games_Box>.Image_Box {
        width: 280px !important;
        height: 280px !important;
    }

    .Games_Box>.Image_Box {
        margin: 10px 5px !important;
    }
}

@media (max-width: 601px) {
    .Games_Box>.Image_Box {
        width: 275px !important;
        height: 275px !important;
    }
}

@media (max-width: 591px) {
    .Games_Box>.Image_Box {
        width: 270px !important;
        height: 270px !important;
    }
}

@media (max-width: 581px) {
    .Games_Box>.Image_Box {
        width: 265px !important;
        height: 265px !important;
    }
}

@media (max-width: 571px) {
    .Games_Box>.Image_Box {
        width: 260px !important;
        height: 260px !important;
    }
}

@media (max-width: 561px) {
    .Games_Box>.Image_Box {
        width: 255px !important;
        height: 255px !important;
    }
}

@media (max-width: 551px) {
    .Games_Box>.Image_Box {
        width: 250px !important;
        height: 250px !important;
    }
}

@media (max-width: 541px) {
    .Games_Box>.Image_Box {
        width: 245px !important;
        height: 245px !important;
    }
}

@media (max-width: 531px) {
    .Games_Box>.Image_Box {
        width: 240px !important;
        height: 240px !important;
    }
}

@media (max-width: 521px) {
    .Games_Box>.Image_Box {
        width: 235px !important;
        height: 235px !important;
    }
}

@media (max-width: 511px) {
    .Games_Box>.Image_Box {
        width: 230px !important;
        height: 230px !important;
    }
}

@media (max-width: 501px) {
    .Games_Box>.Image_Box {
        width: 225px !important;
        height: 225px !important;
    }
}

@media (max-width: 491px) {
    .Games_Box>.Image_Box {
        width: 220px !important;
        height: 220px !important;
    }
}

@media (max-width: 481px) {
    .Games_Box>.Image_Box {
        width: 215px !important;
        height: 215px !important;
    }
}

@media (max-width: 471px) {
    .Games_Box>.Image_Box {
        width: 210px !important;
        height: 210px !important;
    }
}

@media (max-width: 461px) {
    .Games_Box>.Image_Box {
        width: 205px !important;
        height: 205px !important;
    }
}

@media (max-width: 451px) {
    .Games_Box>.Image_Box {
        width: 200px !important;
        height: 200px !important;
    }
}

@media (max-width: 441px) {
    .Games_Box>.Image_Box {
        width: 195px !important;
        height: 195px !important;
    }
}

@media (max-width: 431px) {
    .Games_Box>.Image_Box {
        width: 190px !important;
        height: 190px !important;
    }
}

@media (max-width: 421px) {
    .Games_Box>.Image_Box {
        width: 185px !important;
        height: 185px !important;
    }
}

@media (max-width: 411px) {
    .Games_Box>.Image_Box {
        width: 180px !important;
        height: 180px !important;
    }
}

@media (max-width: 401px) {
    .Games_Box>.Image_Box {
        width: 175px !important;
        height: 175px !important;
    }
}

@media (max-width: 391px) {
    .Games_Box>.Image_Box {
        width: 170px !important;
        height: 170px !important;
    }
}

@media (max-width: 381px) {
    .Games_Box>.Image_Box {
        width: 165px !important;
        height: 165px !important;
    }
}

@media (max-width: 371px) {
    .Games_Box>.Image_Box {
        width: 160px !important;
        height: 160px !important;
    }
}

@media (max-width: 361px) {
    .Games_Box>.Image_Box {
        width: 155px !important;
        height: 155px !important;
    }
}

@media (max-width: 351px) {
    .Games_Box>.Image_Box {
        width: 150px !important;
        height: 150px !important;
    }
}

@media (max-width: 341px) {
    .Games_Box>.Image_Box {
        width: 145px !important;
        height: 145px !important;
    }
}

@media (max-width: 331px) {
    .Games_Box>.Image_Box {
        width: 140px !important;
        height: 140px !important;
    }
}

.Games_Section_Box {
    background-color: var(--Game-Bg-Color) !important;
}

.Image_Box {
    background-color: #ffe88d85 !important;
    border: 2px solid transparent !important;
}

.Image_Box:hover {
    background-color: var(--Clr-Theam) !important;
    border: 2px solid var(--Clr-Theam) !important;
}

.nav>.nav-header>.nav-title {
    margin-top: -7px !important;
}

.nav-title>a>img {
    margin-top: 2px !important;
    margin-bottom: -6px !important;
}

.adsbygoogle {
    display: flex !important;
    justify-content: center !important;
}

.Games_Box {
    margin-bottom: 20px !important;
}

.nav>.nav-links>a {
    padding: 10px 5px 10px 5px !important;
}

@media (max-width: 800px) {
    .nav>.nav-links {
        font-size: 14px !important;
    }

    .nav-title>a>img {
        width: 200px !important;
        margin-left: -20px !important;
    }
}






.Main_Ifram>.Game_Ifram {
    width: 100%;
    height: 700px;
    margin-top: 20px;
    border-radius: 10px;
}

.Game_Ifram>iframe {
    width: 100%;
    height: 700px;
    border-radius: 10px;
}






.autors-widget {
    margin-top: 40px !important;
}

.Description_Text>span {
    color: white !important;
}

@media (max-width: 980px) {
    .New_Link {
        display: var(--Nikal) !important;
    }
}

@media (max-width: 1411px) {
    .Image_Box {
        width: 210px !important;
        height: 210px !important;
    }
}

@media (max-width: 1381px) {
    .Image_Box {
        width: 205px !important;
        height: 205px !important;
    }
}

@media (max-width: 1351px) {
    .Image_Box {
        width: 200px !important;
        height: 200px !important;
    }
}

@media (max-width: 1319px) {
    .Image_Box {
        width: 240px !important;
        height: 240px !important;
    }
}

@media (max-width: 1301px) {
    .Image_Box {
        width: 235px !important;
        height: 235px !important;
    }
}

@media (max-width: 1276px) {
    .Image_Box {
        width: 230px !important;
        height: 230px !important;
    }
}

@media (max-width: 1251px) {
    .Image_Box {
        width: 225px !important;
        height: 225px !important;
    }
}

@media (max-width: 1226px) {
    .Image_Box {
        width: 220px !important;
        height: 220px !important;
    }
}

@media (max-width: 1201px) {
    .Image_Box {
        width: 215px !important;
        height: 215px !important;
    }
}

@media (max-width: 1176px) {
    .Image_Box {
        width: 210px !important;
        height: 210px !important;
    }
}

@media (max-width: 1151px) {
    .Image_Box {
        width: 205px !important;
        height: 205px !important;
    }
}

@media (max-width: 1126px) {
    .Image_Box {
        width: 200px !important;
        height: 200px !important;
    }
}

@media (max-width: 1101px) {
    .Image_Box {
        width: 250px !important;
        height: 250px !important;
    }
}

@media (max-width: 1081px) {
    .Image_Box {
        width: 245px !important;
        height: 245px !important;
    }
}

@media (max-width: 1061px) {
    .Image_Box {
        width: 240px !important;
        height: 240px !important;
    }
}

@media (max-width: 1041px) {
    .Image_Box {
        width: 235px !important;
        height: 235px !important;
    }
}

@media (max-width: 1021px) {
    .Image_Box {
        width: 230px !important;
        height: 230px !important;
    }
}

@media (max-width: 1001px) {
    .Image_Box {
        width: 225px !important;
        height: 225px !important;
    }
}

@media (max-width: 981px) {
    .Image_Box {
        width: 220px !important;
        height: 220px !important;
    }
}

@media (max-width: 961px) {
    .Image_Box {
        width: 215px !important;
        height: 215px !important;
    }
}

@media (max-width: 941px) {
    .Image_Box {
        width: 210px !important;
        height: 210px !important;
    }
}

@media (max-width: 921px) {
    .Image_Box {
        width: 205px !important;
        height: 205px !important;
    }
}

@media (max-width: 901px) {
    .Image_Box {
        width: 200px !important;
        height: 200px !important;
    }
}

@media (max-width: 881px) {
    .Image_Box {
        width: 270px !important;
        height: 270px !important;
    }
}

@media (max-width: 871px) {
    .Image_Box {
        width: 265px !important;
        height: 265px !important;
    }
}

@media (max-width: 856px) {
    .Image_Box {
        width: 260px !important;
        height: 260px !important;
    }
}

@media (max-width: 841px) {
    .Image_Box {
        width: 255px !important;
        height: 255px !important;
    }
}

@media (max-width: 826px) {
    .Image_Box {
        width: 250px !important;
        height: 250px !important;
    }
}

@media (max-width: 811px) {
    .Image_Box {
        width: 245px !important;
        height: 245px !important;
    }
}

@media (max-width: 796px) {
    .Image_Box {
        width: 240px !important;
        height: 240px !important;
    }
}

@media (max-width: 787px) {
    .Image_Box {
        margin: 10px 10px !important;
    }
}

@media (max-width: 781px) {
    .Image_Box {
        width: 235px !important;
        height: 235px !important;
    }
}

@media (max-width: 770px) {
    .Image_Box {
        width: 230px !important;
        height: 230px !important;
        margin: 10px 5px !important;
    }
}














/* .Secgame_Box {} */

@media (max-width: 3000px) {
    .Secgame_Box {
        display: var(--Nikal);
    }
}

@media (max-width: 1319px) {
    .Secgame_Box {
        display: block;
    }
}

@media (max-width: 1101px) {
    .Secgame_Box {
        display: var(--Nikal);
    }
}

#google-anno-sa {
    display: var(--Nikal) !important;
}











/* BlogingPageSection */

.Blog_Page_Section {
    max-width: 999px;
    margin: 0 auto;
    padding: 0 10px;
}

#Blog {
    margin-top: 5px;
    margin-bottom: 5px;
}

.Blog_Image_Box {
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.Flex_Box {
    margin: 0 auto;
}

.Blog_Image_Box>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.Title_Box>h3 {
    margin-left: 8px;
    margin-bottom: 4px;
}

.Flex_Box_Top_20 {
    margin-top: 20px;
    max-width: 900px;
}

.Blog_Description>ul>li {
    list-style: none;
    margin: 10px 10px 0;
}

.Blog_Description>ul {
    margin-top: 10px;
}

.Blog_Description>ul>p {
    margin: 10px 0 0;
}

.Play_Button {
    margin-top: 10px;
}

.Play_Button>a {
    color: #447cff;
}

@media (max-width: 750px) {
    .Blog_Image_Box {
        height: 400px;
    }
}

@media (max-width: 600px) {
    .Blog_Image_Box {
        height: 300px;
    }
}

@media (max-width: 450px) {
    .Blog_Image_Box {
        height: 200px;
    }
}

.Blog_Button {
    display: grid;
    justify-content: center;
}

.Blog_Button>a {
    margin: 0 !important;
}

.Learn_More>.Sec_Main_Blog {
    margin: 0 !important;
}

.Blog_Button>div>a {
    display: block;
    width: 100px;
}

.Blog_Button>.Learn_More>a {
    text-align: center;
    width: 140px;
}

.Blog_Button>.Learn_Play>a {
    background: #970097;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
    width: 140px;
    padding: 10px 0;
    margin-top: 10px;
    border: 2px solid purple;
}

.Play_Button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.Play_Button>a {
    background: #970097;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
    width: 140px;
    padding: 10px 0;
    margin-top: 10px;
    border: 2px solid purple;
    display: block;
}

.Navbar_Hover>a>img {
    height: 50px !important;
}

.nav {
    height: 72px !important;
    padding-top: 13px !important;
    display: inherit !important;
}

#Ads_Section {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}


.Main_Blog_Box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Blog_Image>img {
    width: 100% !important;
    height: 300px !important;
    filter: grayscale(0.4) !important;
    transition: all 1s;
}

.Main_Blog_Box>.Sec_Main_Blog {
    width: 450px;
}

.Blog_Image>img:hover {
    filter: grayscale(0) !important;
    transition: all 1s;
}

.Learn_Play {
    display: none;
}

.Learn_More {
    display: none;
}

#Blog_Size_Width {
    width: 100% !important;
}

.Blog_Heading>h2 {
    position: relative;
    top: 290px;
    z-index: 1;
}


.Blog_Image {
    margin: 0 !important;
}

.Sec_Main_Blog {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.Blog_Description {
    display: none;
}

@media (max-width: 1430px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 445px;
    }
}

@media (max-width: 1415px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 440px;
    }
}

@media (max-width: 1400px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 435px;
    }
}

@media (max-width: 1385px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 430px;
    }
}

@media (max-width: 1370px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 425px;
    }
}

@media (max-width: 1365px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 420px;
    }
}

@media (max-width: 1350px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 415px;
    }
}

@media (max-width: 1335px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 410px;
    }
}

@media (max-width: 1310px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 405px;
    }
}

@media (max-width: 1295px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 400px;
    }
}

@media (max-width: 1280px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 395px;
    }
}

@media (max-width: 1265px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 390px;
    }
}

@media (max-width: 1250px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 385px;
    }
}

@media (max-width: 1235px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 380px;
    }
}

@media (max-width: 1220px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 300px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 375px;
    }
}

@media (max-width: 1205px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 370px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1190px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 365px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1175px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 360px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1160px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 355px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1145px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 350px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1130px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 345px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1115px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 340px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1100px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 335px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1085px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 330px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1070px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 325px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1055px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 250px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 320px;
    }

    .Blog_Heading>h2 {
        top: 250px;
    }
}

@media (max-width: 1040px) {
    .Blog_Image>img {
        width: 100% !important;
        height: 220px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 315px;
    }

    .Blog_Heading>h2 {
        top: 220px;
    }
}

@media (max-width: 1025px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 310px;
    }
}

@media (max-width: 1010px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 305px;
    }
}

@media (max-width: 995px) {
    .Blog_Image>img {
        height: 200px !important;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        width: 300px;
    }

    .Blog_Heading>h2 {
        top: 200px;
        font-size: 20px;
    }

    .Main_Blog_Box>.Sec_Main_Blog {
        margin-bottom: 20px;
    }
}

@media (max-width: 980px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 295px;
    }
}

@media (max-width: 965px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 290px;
    }
}

@media (max-width: 950px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 285px;
    }
}

@media (max-width: 935px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 280px;
    }
}

@media (max-width: 920px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 275px;
    }
}

@media (max-width: 905px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 270px;
    }
}

@media (max-width: 890px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 265px;
    }
}

@media (max-width: 875px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 260px;
    }
}

@media (max-width: 860px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 255px;
    }
}

@media (max-width: 845px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 250px;
    }
}

@media (max-width: 830px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 245px;
    }
}

@media (max-width: 815px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 240px;
    }
}

@media (max-width: 800px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 365px !important;
    }

    .Blog_Image>img {
        height: 240px !important;
    }

    .Blog_Heading>h2 {
        top: 230px;
        font-size: 20px;
    }
}

@media (max-width: 790px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 360px !important;
    }
}

@media (max-width: 780px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 355px !important;
    }
}

@media (max-width: 770px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 350px !important;
    }
}

@media (max-width: 760px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 345px !important;
    }
}

@media (max-width: 750px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 340px !important;
    }
}

@media (max-width: 740px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 335px !important;
    }
}

@media (max-width: 730px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 330px !important;
    }
}

@media (max-width: 720px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 325px !important;
    }
}

@media (max-width: 710px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 320px !important;
    }
}

@media (max-width: 700px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 315px !important;
    }
}

@media (max-width: 690px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 310px !important;
    }
}

@media (max-width: 680px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 305px !important;
    }
}

@media (max-width: 670px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 100% !important;
    }

    .Blog_Image>img {
        height: 400px !important;
    }

    .Blog_Heading>h2 {
        top: 390px;
        font-size: 30px;
    }
}

@media (max-width: 570px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 100% !important;
    }

    .Blog_Image>img {
        height: 300px !important;
    }

    .Blog_Heading>h2 {
        top: 290px;
        font-size: 30px;
    }
}

@media (max-width: 450px) {
    .Main_Blog_Box>.Sec_Main_Blog {
        width: 100% !important;
    }

    .Blog_Image>img {
        height: 200px !important;
    }

    .Blog_Heading>h2 {
        top: 190px;
        font-size: 20px;
    }
}

.owl-controls .owl-pagination .owl-page.active span {
    background: #7c79b3 !important;
}

.owl-controls .owl-pagination .owl-page span {
    height: 10px;
    width: 10px;
    display: block;
    background: #ff8e8e;
    border-radius: 100%;
    margin: 0 5px;
}

.nav>.nav-links>a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.nav>.nav-links {
    margin-right: 10px;
}

.nav>.nav-links>a {
    font-family: inherit;
    font-weight: 600;
}

@media (max-width: 380px) {
    .GB-HomePage>h2 {
        font-size: 30px;
    }
}

@media (max-width: 999px) {
    .MF-Left-Menu {
        display: grid !important;
        justify-content: center;
        width: 100%;
    }

    .MF-Right-Menu {
        justify-content: center;
        width: 100%;
        display: flex;
    }

    .MF-Left-Menu,
    .MF-Right-Menu {
        padding: 0 0 !important;
        margin-top: 10px;
    }

    .MF-Left_Icon {
        text-align: center;
    }

    .MF-Left_Link {
        margin-top: 30px;
    }

    .MF-Left_Icon>img {
        width: 250px !important;
    }

    .MF-Div {
        height: 280px !important;
    }
}

@media (max-width: 570px) {
    .SF-Left {
        width: 100% !important;
        text-align: center !important;
        justify-content: center !important;
    }

    .SF-Right {
        width: 100% !important;
        text-align: center !important;
        justify-content: center !important;
    }

    .SF-Main {
        display: block !important;
    }

    .MF-Icon>a {
        font-size: 22px !important;
    }

    .MF-Icon>a>i {
        font-size: 18px !important;
    }
}

@media (max-width:450px) {
    .MF-Left_Link>ul>li>a {
        font-size: 21px !important;
    }

    .MF-Icon>a>i {
        font-size: 17px !important;
    }
}

.MainFooterPart {
    margin-top: 30px !important;
}