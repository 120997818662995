@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap');

* {
    margin: 0px;
    padding: 0px;
    outline: none;
    box-sizing: border-box;
    font-family: var(--Head-Font);
    transition: all 0.6s;
    font-weight: 900;
}

body {
    background: var(--Bg-Color) !important;
    color: white;
}

a {
    font-family: "Oxanium", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 25px !important;
}

.Container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 10px;
}

.nav {
    align-items: center;
    justify-content: space-between;
}

footer {
    background-color: #121315;
    color: #a7a7a7;
    font-size: 16px;
}

footer * {
    font-family: "Oxanium", sans-serif !important;
    box-sizing: border-box;
    border: none;
    outline: none;
}

.row {
    padding: 1em 1em;
}

.row.primary {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    align-items: stretch;
}

.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2em;
    min-height: 15em;
}

.column>h3 {
    width: 100%;
    text-align: left;
    color: white;
    font-size: 1.1em;
    white-space: nowrap;
}

.column>ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.column>ul>li:not(:first-child) {
    margin-top: 0.8em;
}

.column>ul li a {
    color: #a7a7a7;
    text-decoration: none;
}

.column>ul li a:hover {
    color: #2a8ded;
}

.about p {
    text-align: justify;
    line-height: 2;
    margin: 0;
}

.subscribe>div>input,
.subscribe>div>button {
    font-size: 1em;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
}

.subscribe>div>button {
    background-color: #c7940a;
    color: #ffffff;
}

.social {
    display: flex;
    justify-content: space-around;
    font-size: 2.4em;
    flex-direction: row;
    margin-top: 0.5em;
}

.social i {
    color: #bac6d9;
}

.copyright {
    padding: 0.3em 1em;
    background-color: #25262e;
}

.footer-menu {
    float: left;
}

.footer-menu>a {
    color: #cfd2d6;
    padding: 6px;

    text-decoration: none;
}

.footer-menu>a:hover {
    color: #27bcda;
}

.copyright>p {
    font-size: 0.9em;
    text-align: right;
}

@media screen and (max-width: 850px) {
    .row.primary {
        grid-template-columns: 1fr;
    }

}

.Footer_Ul {
    margin-top: 20px !important;
}

.Footer_Ul>li {
    margin: 10px;
}

.Footer_H3 {
    margin-top: 10px;
}

.Extra_Footer_H3 {
    margin-bottom: 20px;
}

.subscribe>div>input {
    margin-bottom: 10px;
}

footer>div {
    height: auto !important;
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
}

footer {
    font-family: math !important;
}

.Footer_Ul>li>a,
.footer-menu>a,
.column>p,
.copyright>p {
    font-family: "Oxanium" sans-serif !important;
    font-size: 15px !important;
    padding: 3px;
}

@media (max-width: 700px) {
    .HH-Bg-Image {
        height: 250px;
    }
}

.MarAuto {
    margin: 0 auto;
}

.dropdown-menu[data-bs-popper] {
    left: -10px !important;
}

.Search-Bar {
    color: #f50049 !important;
    border: 2px solid #f50049 !important;
    box-shadow: none !important;
    background: transparent !important;
}

.Search-Bar::placeholder {
    color: #f50049 !important;
}


.justifyEnd {
    justify-content: flex-end !important;
}

.Bg-Nav-Color {
    padding-top: 15px;
}

.GG-GameIfram > iframe {
    width: 100%;
    position: fixed;
    height: 100vh;
    bottom: 0;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
}