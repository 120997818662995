@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.Bg-Nav-Color {
    background-color: var(--Bg-Color) !important;
}

.M0auto {
    margin: 0 auto;
}

.Nav_Color {
    color: #fff !important;
}

.Nav_Color:hover {
    color: var(--Clr-Theam) !important;
}

li>.active {
    color: var(--Clr-Theam) !important;
}

.Navbar_Serch {
    background-color: transparent !important;
    border: 1px solid var(--Clr-Theam) !important;
    color: var(--Clr-Theam) !important;
}

.Navbar_Serch::placeholder {
    color: var(--Clr-Theam) !important;
}

.Navbar_SerBtn {
    background-color: transparent !important;
    border: 1px solid var(--Clr-Theam) !important;
    color: var(--Clr-Theam) !important;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    background: yellow;
}

.Games_Box {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.Games_Box>div {
    margin: 10px 0;
    border-radius: 27px;
}

.Games_Box>div>a>img {
    border-radius: 10px;
}

.Main_Fram_Box {
    width: 100%;
    height: 600px;
    margin-top: 10px;
    border-radius: 10px;
}

.Main_Fram_Box>div {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(14px);
}

.Main_Fram_Box {
    background-size: cover;
}

.GG-GameIfram {
    width: 100%;
    height: 600px;
    border-radius: 10px;
}

.GG-GameIfram>iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.GP-Icon > img {
    width: 250px;
    height: 250px;
    border-radius: 10px;
}

.GP-Button > a {
    background: #bd00bd;
    color: white;
    text-decoration: none;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 10px;
    margin-top: 10px;
}

.MD-Class {
    background-color: #fffdf549;
    border-radius: 10px;
    border: 2px solid var(--Clr-Theam);
    color: rgb(0, 0, 0);
    padding: 0 10px;
}

.MD-Info>p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 18px;
    font-weight: 600;
}

.MD-Info>p>b {
    font-size: 21px;
    color: rgb(185, 255, 215);
}

.MD-Info>h3 {
    color: var(--Clr-Theam);
    font-weight: 800;
}

.MainFooterPart {
    border-radius: 10px 10px 0px 0px;
    box-sizing: border-box;
    width: 99%;
    margin: 0 auto;
}

.MF-Left_Icon>img {
    width: 230px;
}

.MF-Left_Link>ul {
    display: flex;
    padding-left: 0;
    margin: 0;
}

.MF-Left-Menu {
    float: left;
}

.MF-Right-Menu {
    float: right;
}

.MF-Left_Link>ul>li {
    list-style: none;
}

.MF-Left_Link>ul>li>a {
    text-decoration: none;
    color: white;
    padding: 5px 7px;
    font-size: 18px;
}

.MF-Left_Link>ul>li>a:hover {
    color: var(--Clr-Theam);
}

.MF-Left-Menu {
    display: flex;
    align-items: center;
}

.MF-Right_Icon {
    display: flex;
}

.MF-Icon {
    margin: 10px 8px;
}

.MF-Icon>a>i {
    color: white;
    font-size: 25px;
    border: 2px solid white;
    padding: 10px 12px;
    border-radius: 100%;
    margin-top: 10px;
}

.MF-Icon>a>i:hover {
    color: var(--Clr-Theam);
    border: 2px solid var(--Clr-Theam);
}

.MF-Left-Menu,
.MF-Right-Menu {
    padding: 30px 0;
}

.SF-Main {
    display: flex;
}

.SF-Left > a > a {
    color: var(--Clr-Theam);
    text-decoration: none;
    font-size: 20px !important;
}

.SF-Left > a {
    color: white;
    text-decoration: none;
    font-size: 20px !important;
}

.SF-Left {
    width: 60%;
}

.SF-Right {
    display: flex;
    justify-content: flex-end;
    width: 40%;
}

.SF-Right > a {
    color: white !important;
    text-decoration: none !important;
    font-size: 20px !important;
    margin-left: 20px;
}

.MF-Div {
    height: 130px
}

.Sec_Footer {
    border-top: 1px solid var(--Clr-Theam) !important;
    padding-top: 14px !important;
    padding-bottom: 10px !important;
}

.G71 { background: url("../Assets/b71.png") no-repeat center; }
.G72 { background: url("../Assets/b72.png") no-repeat center; }
.G73 { background: url("../Assets/b73.png") no-repeat center; }
.G74 { background: url("../Assets/b74.png") no-repeat center; }
.G75 { background: url("../Assets/b75.png") no-repeat center; }
.G76 { background: url("../Assets/b76.png") no-repeat center; }
.G77 { background: url("../Assets/b77.png") no-repeat center; }
.G78 { background: url("../Assets/b78.png") no-repeat center; }
.G79 { background: url("../Assets/b79.png") no-repeat center; }
.G80 { background: url("../Assets/b80.png") no-repeat center; }

.G90 { background: url("../Assets/b81.png") no-repeat center; }
.G81 { background: url("../Assets/b82.png") no-repeat center; }
.G82 { background: url("../Assets/b83.png") no-repeat center; }
.G83 { background: url("../Assets/b84.png") no-repeat center; }
.G84 { background: url("../Assets/b85.png") no-repeat center; }
.G85 { background: url("../Assets/b86.png") no-repeat center; }
.G86 { background: url("../Assets/b87.png") no-repeat center; }
.G87 { background: url("../Assets/b88.png") no-repeat center; }
.G88 { background: url("../Assets/b89.png") no-repeat center; }
.G89 { background: url("../Assets/b90.png") no-repeat center; }

.G91 { background: url("../Assets/b91.png")no-repeat center; }
.G92 { background: url("../Assets/b92.png")no-repeat center; }
.G93 { background: url("../Assets/b93.png")no-repeat center; }
.G94 { background: url("../Assets/b94.png")no-repeat center; }
.G95 { background: url("../Assets/b95.png")no-repeat center; }
.G96 { background: url("../Assets/b96.png")no-repeat center; }
.G97 { background: url("../Assets/b97.png")no-repeat center; }
.G98 { background: url("../Assets/b98.png")no-repeat center; }
.G99 { background: url("../Assets/b99.png")no-repeat center; }
.G100 {background: url("../Assets/b100.png") no-repeat center; }


.b71 { background: url("../Assets/71.png") no-repeat center; }
.b72 { background: url("../Assets/72.png") no-repeat center; }
.b73 { background: url("../Assets/73.png") no-repeat center; }
.b74 { background: url("../Assets/74.png") no-repeat center; }
.b75 { background: url("../Assets/75.png") no-repeat center; }
.b76 { background: url("../Assets/76.png") no-repeat center; }
.b77 { background: url("../Assets/77.png") no-repeat center; }
.b78 { background: url("../Assets/78.png") no-repeat center; }
.b79 { background: url("../Assets/79.png") no-repeat center; }
.b80 { background: url("../Assets/80.png") no-repeat center; }

.b90 { background: url("../Assets/81.png") no-repeat center; }
.b81 { background: url("../Assets/82.png") no-repeat center; }
.b82 { background: url("../Assets/83.png") no-repeat center; }
.b83 { background: url("../Assets/84.png") no-repeat center; }
.b84 { background: url("../Assets/85.png") no-repeat center; }
.b85 { background: url("../Assets/86.png") no-repeat center; }
.b86 { background: url("../Assets/87.png") no-repeat center; }
.b87 { background: url("../Assets/88.png") no-repeat center; }
.b88 { background: url("../Assets/89.png") no-repeat center; }
.b89 { background: url("../Assets/90.png") no-repeat center; }

.b91 { background: url("../Assets/91.png")no-repeat center; }
.b92 { background: url("../Assets/92.png")no-repeat center; }
.b93 { background: url("../Assets/93.png")no-repeat center; }
.b94 { background: url("../Assets/94.png")no-repeat center; }
.b95 { background: url("../Assets/95.png")no-repeat center; }
.b96 { background: url("../Assets/96.png")no-repeat center; }
.b97 { background: url("../Assets/97.png")no-repeat center; }
.b98 { background: url("../Assets/98.png")no-repeat center; }
.b99 { background: url("../Assets/99.png")no-repeat center; }
.b100 {background: url("../Assets/100.png") no-repeat center; }

/* .G41 { background: url("https://thezgame.com/Assets/Game41.png") no-repeat center; }
.G42 { background: url("https://thezgame.com/Assets/Game42.png") no-repeat center; }
.G43 { background: url("https://thezgame.com/Assets/Game43.png") no-repeat center; }
.G44 { background: url("https://thezgame.com/Assets/Game44.png") no-repeat center; }
.G45 { background: url("https://thezgame.com/Assets/Game45.png") no-repeat center; }
.G46 { background: url("https://thezgame.com/Assets/Game46.png") no-repeat center; }
.G47 { background: url("https://thezgame.com/Assets/Game47.png") no-repeat center; }
.G48 { background: url("https://thezgame.com/Assets/Game48.png") no-repeat center; }
.G49 { background: url("https://thezgame.com/Assets/Game49.png") no-repeat center; }
.G50 { background: url("https://thezgame.com/Assets/Game50.png") no-repeat center; }

.G51 { background: url("https://thezgame.com/Assets/Game51.png") no-repeat center; }
.G52 { background: url("https://thezgame.com/Assets/Game52.png") no-repeat center; }
.G53 { background: url("https://thezgame.com/Assets/Game53.png") no-repeat center; }
.G54 { background: url("https://thezgame.com/Assets/Game54.png") no-repeat center; }
.G55 { background: url("https://thezgame.com/Assets/Game55.png") no-repeat center; }
.G56 { background: url("https://thezgame.com/Assets/Game56.png") no-repeat center; }
.G57 { background: url("https://thezgame.com/Assets/Game57.png") no-repeat center; }
.G58 { background: url("https://thezgame.com/Assets/Game58.png") no-repeat center; }
.G59 { background: url("https://thezgame.com/Assets/Game59.png") no-repeat center; }
.G60 { background: url("https://thezgame.com/Assets/Game60.png") no-repeat center; }

.G61 { background: url("https://thezgame.com/Assets/Game61.png") no-repeat center; }
.G62 { background: url("https://thezgame.com/Assets/Game62.png") no-repeat center; }
.G63 { background: url("https://thezgame.com/Assets/Game63.png") no-repeat center; }
.G64 { background: url("https://thezgame.com/Assets/Game64.png") no-repeat center; }
.G65 { background: url("https://thezgame.com/Assets/Game65.png") no-repeat center; }
.G66 { background: url("https://thezgame.com/Assets/Game66.png") no-repeat center; }
.G67 { background: url("https://thezgame.com/Assets/Game67.png") no-repeat center; }
.G68 { background: url("https://thezgame.com/Assets/Game68.png") no-repeat center; }
.G69 { background: url("https://thezgame.com/Assets/Game69.png") no-repeat center; }
.G70 { background: url("https://thezgame.com/Assets/Game70.png") no-repeat center; } */

.Sec_Main_Blog {
    text-decoration: none !important;
}

.Sec_Main_Blog>div {
    background-size: cover;
    width: 100%;
    height: 250px;
    margin: 10px 0;
    border-radius: 10px;
    position: relative;
    z-index: 9;
}

.Sec_Main_Blog>div>h1 {
    position: relative;
    bottom: 0;
    text-align: center;
    top: 10px;
    color: var(--Clr-Theam);
    background-color: rgb(0, 0, 0);
}

.Sec_Main_Blog div:hover h1 {
    background-color: rgb(0, 0, 0);
}

.Sec_Main_Blog>div>article {
    color: transparent;
    font-size: 20px !important;
    padding: 5px;
}

.Sec_Main_Blog div:hover article {
    color: var(--Clr-Theam);
    background-color: rgb(0, 0, 0);
}

.wrapper {
    width: 100%;
    max-width: 1240px;
    margin: auto;
    padding: 80px 20px;
    box-sizing: border-box;
}

.wrapper>h2 { 
    color: var(--Clr-Theam);
}

.wrapper>p { 
    color: white;
}